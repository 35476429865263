"use strict";

let navlinks = document.querySelectorAll(".jumplink");
for (var i = 0; i < navlinks.length; i++) {
  navlinks[i].addEventListener("click", function (event) {
    event.preventDefault();

    let target = this.href.split("#");
    dataLayer.push({
      event: "Navigation",
      navigationLabel: this.innerHTML,
    });
    closeSideMenu();
    if (target[0] === location.origin + location.pathname) {
      window.scrollTo({
        top: document.querySelector(this.hash).offsetTop,
        behavior: "smooth",
      });
    } else {
      location.href = this.href;
    }
  });
}

function checkQueryString(search) {
  return window.location.search
    .replace("?", "")
    .split("&")
    .map((item) => {
      return item.split("=");
    })
    .find((param) => param[0] === search);
}

window.addEventListener("DOMContentLoaded", (e) => {
  // Get Expires Date
  let banner_date = window.localStorage.getItem(
    "pencil_banner_interaction_date"
  );
  // Reset Storage if Past date
  if (banner_date !== null && Date.now() > banner_date) {
    window.localStorage.removeItem("pencil_banner_interaction");
    window.localStorage.removeItem("pencil_banner_interaction_date");
  }

  /* let pencil__content = document.querySelector(".pencil__content");
  let now = Date.now();
  let future = new Date("8/8/2021").getTime();
  const day = 1000 * 3600 * 24;

  let days = parseInt((future - now) / day);
  //console.log("Days: " + days);
  pencil__content.innerHTML = pencil__content.innerHTML.replace("XX", days); */

  let pencil_banner = document.querySelector(".pencil-banner"),
    banner_seen = window.localStorage.getItem("pencil_banner_interaction"),
    expire_time = 12096e5; // Expiration time in seconds, 2 weeks
  if (pencil_banner) {
    let banner_id = pencil_banner.id;
    // Show Pencil banner if not already seen
    if (banner_seen === null || banner_seen !== banner_id) {
      window.addEventListener("load", (event) => {
        document.querySelector(".pencil-banner").classList.remove("close");
      });
    }

    let pencil__close = pencil_banner.querySelector(".pencil__close");
    pencil__close.addEventListener("click", function (event) {
      event.preventDefault();
      // Close banner and set storage values
      window.localStorage.setItem("pencil_banner_interaction", banner_id);
      window.localStorage.setItem(
        "pencil_banner_interaction_date",
        Date.now() + expire_time
      );
      document.querySelector(".pencil-banner").classList.add("close");
    });

    /* let pencil_button = pencil_banner.querySelector(".button");
    pencil_button.addEventListener("click", function (event) {
      // Close banner and set storage values
      window.localStorage.setItem("pencil_banner_interaction", banner_id);
      window.localStorage.setItem(
        "pencil_banner_interaction_date",
        Date.now() + expire_time
      );
    }); */
  }

  let nav_toggle = document.querySelector(".nav--toggle"),
    nav_close = document.querySelector(".nav--close");
  nav_toggle.addEventListener("click", (e) => {
    e.preventDefault();
    openSideMenu();
  });
  nav_close.addEventListener("click", (e) => {
    e.preventDefault();
    closeSideMenu();
  });
  loadVideoAPI();
});

function closeSideMenu() {
  return document.body.classList.remove("nav--open");
}

function openSideMenu() {
  return document.body.classList.add("nav--open");
}

let $vimeo_id = false;
class Modal {
  constructor($selector) {
    this.selector = $selector;
    this.elem = document.querySelector(this.selector);
    this.modal_content = this.elem.innerHTML;
    this.modal_core = this.createModal();
    if (localStorage.getItem("modals") == null) {
      localStorage.setItem("modals", JSON.stringify([]));
    }
    this.viewed = JSON.parse(localStorage.getItem("modals"));
    if (this.modal_content) {
      this.showModal();
      this.attachEvents();
    }
  }
  createModal() {
    let modal_classes = this.elem.classList.contains("modal-content--fill")
      ? "modal modal--wide"
      : "modal";

    let modaldiv = document.createElement("div");
    modaldiv.className = modal_classes;
    modaldiv.innerHTML = `
        <div class="modal-wrap"></div>
        <a href="#" class="modal-close" aria-label="Close Modal"></a>
    `;
    document.body.appendChild(modaldiv);
    return document.querySelector(".modal");
  }
  showModal() {
    if (this.viewed.indexOf(this.selector) !== -1) {
      if (this.elem.dataset.show == "once") {
        return;
      }
    }
    this.modal_core.querySelector(".modal-wrap").innerHTML = this.modal_content;
    document.body.classList.add("modal_open");
  }
  hideModal() {
    document.body.classList.remove("modal_open");
    localStorage.setItem(
      "modals",
      JSON.stringify([...this.viewed, this.selector])
    );
    this.viewed = localStorage.getItem("modals");
    this.modal_core.remove();
  }
  attachEvents() {
    let _this = this;
    this.modal_core
      .querySelector(".modal-close")
      .addEventListener("click", function () {
        _this.hideModal();
      });
    /* let has_vimeoID = this.elem.dataset.vimeoid;
    if (has_vimeoID) {
      $vimeo_id = this.elem.dataset.vimeoid;
      initVimeoVideo(this.modal_core);
    } */
  }
}

/* if (checkQueryString("modal")) {
  let modal = new Modal("#modal");
} */

//let modal = new Modal("#covid");
window.addEventListener("DOMContentLoaded", (e) => {
  let modal__toggle = document.querySelector(".modal--toggle");
  if (modal__toggle) {
    modal__toggle.addEventListener("click", function (event) {
      event.preventDefault();
      let target = event.target;
      if (event.target.tagName != "A") {
        target = target.parentNode;
      }
      let modal = new Modal(target.hash);
    });
  }
});
function loadVideoAPI($elem = null) {
  if (typeof Vimeo !== "object") {
    let tag = document.createElement("script");
    tag.src = "https://player.vimeo.com/api/player.js";
    let firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
}

function initVimeoVideo($player = null) {
  //let iframe = $elem.querySelector("#");
  console.log("initVimeoVideo");
  console.log($player);
  let options = {
    id: $player.dataset.vimeoid,
  };
  let vimeo = new Vimeo.Player($player, options);
  vimeo.setColor("#00cbcd");
  vimeo.setVolume(0.5);
  vimeo.play();
}

const about_video = document.querySelector(".about-video");
if (about_video) {
  about_video.addEventListener("click", (e) => {
    e.preventDefault();
    about_video.classList.toggle("playing");
    initVimeoVideo(about_video);
  });
}

let resourceLinks = document.querySelectorAll(".resource_list .resource a");
if (resourceLinks.length) {
  for (var i = 0; i < resourceLinks.length; i++) {
    resourceLinks[i].addEventListener("click", function (event) {
      event.preventDefault();
      dataLayer.push({
        event: "Navigation",
        navigationLabel: "Resource: " + this.title,
      });
      location.href = this.href;
    });
  }
}

class AgendaSlider {
  constructor() {
    this.agenda_controls = Array.from(
      document.querySelectorAll(".agenda-controls a")
    );
    this.slides = Array.from(
      document.querySelectorAll(".agenda-slider .slide")
    );
    this.slide_track = document.querySelector(".agenda-slider-track");
    this.currentSlide = 0;
    this.wrapper_width = 1325;
    this.slide_width = 0;
    this.setTrackWidth();
    this.attachClickListener();
    this.slideTo(0);
  }

  attachClickListener() {
    let _this = this;
    this.agenda_controls.forEach((elem) => {
      elem.addEventListener("click", function (event) {
        event.preventDefault();
        if (elem.dataset.direction == "next") {
          _this.slideTo(_this.currentSlide + 1);
        } else {
          _this.slideTo(_this.currentSlide - 1);
        }
      });
    });

    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 39) {
        _this.slideTo(_this.currentSlide + 1);
      }
      if (e.keyCode == 37) {
        _this.slideTo(_this.currentSlide - 1);
      }
    });

    this.slides.forEach((slide) => {
      slide.addEventListener("click", function (event) {
        if (event.target.tagName.toLowerCase() === "a") {
          return true;
        }
        event.preventDefault();
        _this.slideTo(slide.dataset.slide);
      });
    });

    window.addEventListener("resize", function () {
      _this.setTrackWidth();
    });
  }

  setTrackWidth() {
    let track_width = 0;
    let active_slide = this.slides[this.currentSlide];
    this.slide_width =
      active_slide.clientWidth +
      parseInt(
        window.getComputedStyle(active_slide).getPropertyValue("margin-right")
      );
    this.slides.forEach((slide) => {
      track_width += this.slide_width;
    });
    this.slide_track.style.width = track_width * 1.1 + "px";
    switch (true) {
      case window.innerWidth >= this.wrapper_width:
        this.slide_track.style.left =
          window.innerWidth / 2 - this.wrapper_width / 2 - 8 + "px";
        break;
      case window.innerWidth >= 414:
        this.slide_track.style.left = "2.5%";
        break;
      default:
        this.slide_track.style.left = "0";
        break;
    }
    this.slide_track.style.transform =
      "translateX(" +
      this.slide_width * parseInt(active_slide.dataset.slide) * -1 +
      "px)";
  }

  slideTo(i) {
    let slide = this.slides[i];
    this.currentSlide = i;
    if (!slide) {
      slide = this.slides[0];
      this.currentSlide = 0;
    }
    let slideOffset = window.innerWidth / 2 - this.slide_width / 2;
    let slide_track = this.slide_width * parseInt(slide.dataset.slide) * -1;
    this.slide_track.style.transform = "translateX(" + slide_track + "px)";
  }
}
if (document.querySelector(".agenda-slider")) {
  const agenda_slider = new AgendaSlider();
}

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});
